import React from "react";
import styles from "./posterModal.module.scss";
import { Modal } from "components/Common/Modal";
import classnames from "classnames/bind";
import { Button } from "components/Common/Button";

const classNameBuilder = classnames.bind(styles);

export const PosterModal = ({
  portalProps,
  className,
  contentStyle,
  title,
  subtitle,
  onButtonClick,
  buttonProps,
  buttonLabel,
}) => {
  return (
    <Modal
      portalProps={portalProps}
      className={styles["modal-container"]}
      contentClassName={classNameBuilder("modal", className)}
      contentStyle={contentStyle}
      closable={false}
      header={null}
    >
      <div className={styles["container"]}>
        <div className={styles["gradient"]} />
        <div className={styles["paragraph"]}>
          <div className={styles["paragraph-title"]}>{title}</div>
          <div className={styles["paragraph-content"]}>{subtitle}</div>
        </div>
        <Button colorful large onClick={onButtonClick} {...buttonProps}>
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  );
};
