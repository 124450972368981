import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUsersAccessors } from "./database/useUsersAccessors";
import {
  useLocalStorage,
  useLocalStorageRemover,
} from "../hooks/useLocalStorage";
import { LAST_CHARACTER_KEY } from "../components/Characters/constants/charactersConsts";
import { LAST_SHIP_KEY } from "../components/Ships/constants/shipsConsts";
import { LAST_GROUPS_KEY } from "../components/Groups/constants/groupsConsts";
import { useDocumentSync } from "hooks/useDocumentSync";
import { isNil, noop } from "lodash";
import { DEFAULT_TUTORIAL_STATUSES } from "components/Tutorial/consts/tutorialConsts";
import { useDocumentManager } from "./database/DocumentManagerContext";
import { USERS_QUERY_KEY } from "constants/usersQueryKey";
import { wildsailor } from "components/Compendium/images";
import { currentVersion } from "components/ChangeLog/versions";

const AuthContext = createContext(null);
export const LOCAL_STORAGE_UID_KEY = "uid";

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);
  const { check, addIgnoreWithId, read, updateLatestSync, subscribe } =
    useUsersAccessors();
  const { provider, auth } = useMemo(() => {
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    provider.setCustomParameters({
      prompt: "select_account",
    });
    const auth = getAuth();
    auth.languageCode = auth.useDeviceLanguage();
    return { provider, auth };
  }, []);
  const [authUser, setAuthUser] = useState();
  const authUserRef = useRef(authUser);
  authUserRef.current = authUser;
  const [shouldSetupUser, setShouldSetupUser] = useState(false);
  const { get: getFromCache, set: setInCache } = useDocumentManager();

  const {
    storageValue: localStorageUid,
    setStorageValue: setLocalStorageUid,
    removeStorageValue: removeLocalStorageUid,
    getCurrentStorageValue: getCurrentUid,
    refetch: refetchCurrentUid,
  } = useLocalStorage(LOCAL_STORAGE_UID_KEY);
  const removeGroupId = useLocalStorageRemover(LAST_GROUPS_KEY);
  const removeCharacterId = useLocalStorageRemover(LAST_CHARACTER_KEY);
  const removeShipId = useLocalStorageRemover(LAST_SHIP_KEY);

  useEffect(() => {
    if (auth) {
      const unsubscribe = auth.onAuthStateChanged((newUser, ...rest) => {
        if (newUser) {
          setAuthUser(newUser);
          setAuthLoaded(true);
        } else {
          setAuthUser(null);
          setAuthLoaded(false);

          // if you had a user already, then this was likely caused by someone signing out in a different tab
          if (authUserRef.current) {
            removeLocalStorageUid("");
          }
        }
      });

      return () => {
        unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  // if we want the user Doc to update when it updates in firestore
  //   this has bad side effects for the Groups dropdown b/c the dropdown
  //   refetches when the recentlyUsedGroups array changes
  useDocumentSync({
    subscribe,
    id: localStorageUid,
    queryKey: USERS_QUERY_KEY,
    disabled: !localStorageUid,
    refetchGroup: noop,
    onUpdateSuccess: setUser,
    warningName: "user",
  });

  const resetPassword = useCallback(
    (email) => {
      setError(null);
      return sendPasswordResetEmail(auth, email).catch((e) => {
        setError(e);
        throw e;
      });
    },
    [auth]
  );

  const signOut = useCallback(async () => {
    setShouldSetupUser(false);
    removeLocalStorageUid();
    refetchCurrentUid();
    await auth.signOut();
    setUser(null);
    setAuthUser(null);
    removeGroupId();
    removeCharacterId();
    removeShipId();
  }, [
    auth,
    refetchCurrentUid,
    removeCharacterId,
    removeGroupId,
    removeLocalStorageUid,
    removeShipId,
  ]);

  const getUid = useCallback(() => {
    return getCurrentUid() || null;
  }, [getCurrentUid]);

  useEffect(() => {
    const curUserId = getUid();
    const curAuthUserId = authUser?.uid;

    const hasCurUser = !isNil(curUserId);
    const hasAuthUser = !isNil(authUser);

    // if there is a cur user ID and it doesn't match the auth logged in user, logout the user
    if (
      authLoaded &&
      hasAuthUser &&
      hasCurUser &&
      curAuthUserId !== curUserId
    ) {
      signOut().then(() => {
        window.location.reload();
      });
    }
  }, [authUser?.uid, authLoaded, getUid, signOut, authUser]);

  const readUser = useCallback(
    ({ forceRefresh } = {}) => {
      const uid = getUid();

      if (!authLoaded || !uid || (authLoaded && !authUser)) {
        return null;
      }

      setLoading(true);

      return new Promise((resolve) => {
        const cachedUser = getFromCache([USERS_QUERY_KEY, uid]);

        if (!forceRefresh && cachedUser) {
          return resolve(cachedUser);
        }

        resolve(read(uid));
      })
        .then((user) => {
          setUser(user);
          setInCache([USERS_QUERY_KEY, uid], () => user);
        })
        .catch((error) => {
          console.error(error);
          setError(error);
          signOut();
        })
        .finally(() => setLoading(false));
    },
    [getUid, authLoaded, authUser, getFromCache, read, setInCache, signOut]
  );

  const signIn = useCallback(
    async ({ email, password, onSuccess = noop } = {}) => {
      setLoading(true);
      setError(null);

      let promise;

      if (isNil(email) && isNil(password)) {
        promise = signInWithPopup(getAuth(), provider);
      } else {
        promise = signInWithEmailAndPassword(getAuth(), email, password);
      }
      await promise
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          const {
            displayName = "New User",
            metadata,
            photoURL = wildsailor,
            uid,
          } = result.user;
          const doesUserAlreadyExist = await check(uid);

          if (!doesUserAlreadyExist) {
            setShouldSetupUser(true);
          }

          const user = {
            displayName,
            photoURL,
            uid,
            tutorialStatuses: DEFAULT_TUTORIAL_STATUSES,
            lastLoadedVersion: currentVersion,
            ...metadata,
          };
          const addedDoc = await addIgnoreWithId(uid, user);
          setUser(addedDoc);
          setInCache([USERS_QUERY_KEY, uid], () => addedDoc);
          setLocalStorageUid(uid);
          onSuccess({ uid, doc: addedDoc });
        })
        .catch((error) => {
          console.log(error);
          const { code, message } = error;
          // code -> auth/cancelled-popup-request
          setError({
            code,
            message,
          });
          signOut();
          // The email of the user's account used.
          // const email = error.customData.email;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addIgnoreWithId, check, provider, setInCache, setLocalStorageUid, signOut]
  );

  const createUser = useCallback(
    ({ email, password }) => {
      setError(null);
      return createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          return signIn({ email, password });
        })
        .catch((e) => {
          setError(e);
          throw e;
        });
    },
    [auth, signIn]
  );

  const updateUser = useCallback(
    (updater, { shouldValidateAfterUpdate } = {}) => {
      if (!user) {
        console.error("updated user too early");
        return;
      }

      return updateLatestSync(user.uid, updater, {
        shouldMergeUpdateAndData: true,
      })
        .then((data) => {
          if (shouldValidateAfterUpdate) {
            return readUser({ forceRefresh: shouldValidateAfterUpdate });
          } else {
            setUser(data);
            setInCache([USERS_QUERY_KEY, data?.uid], () => data);

            return data;
          }
        })
        .catch((err) => console.error(err));
    },
    [readUser, setInCache, updateLatestSync, user]
  );

  const value = useMemo(() => {
    return {
      user: authLoaded ? user : null,
      userId: localStorageUid,
      error,
      setError,
      loading,
      signIn,
      signOut,
      getUid,
      readUser,
      updateUser,
      setUser,
      createUser,
      resetPassword,
      authUser,
      authLoaded,
      shouldSetupUser,
      setShouldSetupUser,
    };
  }, [
    authLoaded,
    user,
    localStorageUid,
    error,
    loading,
    signIn,
    signOut,
    getUid,
    readUser,
    updateUser,
    createUser,
    resetPassword,
    authUser,
    shouldSetupUser,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
