import { get, mapValues, pick } from "lodash";
import { useCurrentGroup } from "./useCurrentGroup";
import { EXPANSIONS, EXPANSIONS_VALUES } from "constants/expansions";
import { useMemo } from "react";

export const GROUP_FEATURE_FLAGS = {
  [EXPANSIONS.BASE_REACHES]: "baseReachesEnabled",
  [EXPANSIONS.SAR]: "sarEnabled",
  [EXPANSIONS.SAR_AIR]: "sarEnabled",
  [EXPANSIONS.SAR_SUB]: "sarEnabled",
  [EXPANSIONS.SAR_REACHES]: "sarReachesEnabled",
  [EXPANSIONS.SAR_NON_STANDARD]: "sarNonStandardEnabled",
  [EXPANSIONS.ONE_ARMED_SCISSOR]: "oneArmedScissorEnabled",
};

export const useGroupFF = (featureFlagKey, defaultValue) => {
  const { groupDoc } = useCurrentGroup();

  return get(groupDoc, featureFlagKey, defaultValue);
};

export const useEnabledGroupFFs = () => {
  const { groupDoc } = useCurrentGroup();
  const enabledFFs = useMemo(
    () =>
      mapValues(GROUP_FEATURE_FLAGS, (key) =>
        groupDoc ? get(groupDoc, key) : false
      ),
    [groupDoc]
  );

  return { enabledFFs, hasGroup: Boolean(groupDoc) };
};

export const useEnabledExpansions = () => {
  const enabledGroupFFs = useEnabledGroupFFs();
  const enabledExpansions = useMemo(
    () => pick(enabledGroupFFs.enabledFFs, EXPANSIONS_VALUES),
    [enabledGroupFFs.enabledFFs]
  );

  return {
    ...enabledGroupFFs,
    enabledExpansions,
  };
};
