import { useEffect } from "react";
import { useGroupsAccessors } from "../contexts/database/useGroupsAccessors";
import { useQuery } from "@tanstack/react-query";
import { GROUPS_PATH } from "../components/Groups/constants/groupsConsts";
import { useGroupId } from "./useGroupId";
import { useDocumentSync } from "./useDocumentSync";
import { useAuth } from "contexts/AuthContext";
import { USER_ROLES } from "constants/userRoles";
import { get } from "lodash";
import { useNavigator } from "components/Router/NavigatorContext";
import { useDocumentManager } from "contexts/database/DocumentManagerContext";
import { GROUPS_QUERY_KEY } from "./useGroups";

const DEFAULT_MEMBERS = [];

export const useCurrentGroup = ({
  groupMemberKey,
  groupMemberValue,
  disabled,
  clearMember,
  shouldAllowRedirect,
  shouldRedirectNoGroup,
  pagePrefix,
  shouldSync,
} = {}) => {
  const { user } = useAuth();
  const { subscribe, read: readGroup } = useGroupsAccessors();
  const groupId = useGroupId();
  const { navigate } = useNavigator();
  const { get: getFromCache, set: setInCache } = useDocumentManager();

  const {
    data: groupDoc,
    isFetching: loadingGroupDoc,
    refetch: refetchGroup,
  } = useQuery({
    queryKey: [GROUPS_QUERY_KEY, groupId],
    queryFn: async () => {
      if (!groupId) {
        return null;
      }

      const cachedValue = getFromCache([GROUPS_QUERY_KEY, groupId]);

      if (cachedValue) {
        return cachedValue;
      }

      const group = await readGroup(groupId);

      setInCache([GROUPS_QUERY_KEY, groupId], () => group);

      return group;
    },
    placeholderData: {},
    gcTime: 0,
  });
  const groupDocMembers = groupDoc?.[groupMemberKey] ?? DEFAULT_MEMBERS;

  useDocumentSync({
    subscribe,
    id: groupId,
    queryKey: GROUPS_QUERY_KEY,
    disabled: !shouldSync || !groupId,
    refetchGroup,
    warningName: "group",
  });

  useEffect(() => {
    if (shouldRedirectNoGroup && !groupId) {
      navigate({ to: `/${GROUPS_PATH}` });
    }
  }, [groupId, navigate, pagePrefix, shouldRedirectNoGroup]);

  useEffect(() => {
    // if the user didn't provide a key OR the groupDoc is loading OR this hook is not allowed to redirect, should never navigate
    if (loadingGroupDoc || !shouldAllowRedirect || !groupMemberKey) {
      return;
    }

    const hasNoGroupId = !groupId;
    const groupDoesntExist = !groupDoc;
    const groupDoesntHaveMember = !groupDocMembers.includes(groupMemberValue);

    // if the member value is not in this group, clear it and keep them on the page
    if (hasNoGroupId || groupDoesntExist) {
      clearMember();
      navigate({ to: `/${GROUPS_PATH}` });
    } else if (groupDoesntHaveMember) {
      clearMember();
      navigate({ to: `/${pagePrefix}` });
    }
  }, [
    clearMember,
    disabled,
    groupDoc,
    groupDocMembers,
    groupId,
    groupMemberKey,
    groupMemberValue,
    loadingGroupDoc,
    navigate,
    pagePrefix,
    shouldAllowRedirect,
  ]);

  return {
    groupDoc,
    isOwner: Boolean(get(groupDoc, ["userRoles", user?.uid, USER_ROLES.OWNER])),
    loadingGroupDoc,
    groupId,
    refetchGroup,
  };
};
