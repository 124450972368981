import { isFunction } from "lodash";
import { useCallback, useRef, useState } from "react";

export const useStateRef = (initialState) => {
  const [state, rawSetState] = useState(initialState);
  const ref = useRef(initialState);

  const setState = useCallback((arg) => {
    if (isFunction(arg)) {
      return rawSetState((prev) => {
        const result = arg(prev);
        ref.current = result;
        return result;
      });
    }

    ref.current = arg;
    return rawSetState(arg);
  }, []);

  return [state, setState, ref];
};
