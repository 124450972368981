import React, { useMemo } from "react";
import { useAuth } from "contexts/AuthContext";
import { usePortal } from "contexts/PortalContext";
import { isMobile } from "react-device-detect";
import { usePage } from "hooks/usePage";
import { useActiveTutorials } from "./hooks/useActiveTutorials";
import { isEmpty } from "lodash";
import { useAppHeader } from "contexts/AppHeaderContext";
import { TUTORIALS_CONFIG } from "./consts/tutorialsConfig";
import { BaseTutorial } from "./BaseTutorial";

export const Tutorials = () => {
  const { user } = useAuth();
  const [page] = usePage();
  const {
    settingsDrawer: {
      portalProps: {
        isPortalComponentRendered: isSettingsDrawerOpen,
        setIsPortalComponentRendered: setSettingsDrawerOpen,
      },
    },
  } = useAppHeader();
  const { activeTutorialIds, ...rest } = useActiveTutorials();

  // no tutorials while on join group page
  if (
    !user ||
    isEmpty(activeTutorialIds) ||
    isMobile ||
    page === "join-group"
  ) {
    return null;
  }

  return activeTutorialIds.map((tutorialId) => (
    <Tutorial
      key={tutorialId}
      tutorialId={tutorialId}
      isSettingsDrawerOpen={isSettingsDrawerOpen}
      setSettingsDrawerOpen={setSettingsDrawerOpen}
      {...rest}
    />
  ));
};

const Tutorial = ({
  tutorialId,
  isSettingsDrawerOpen,
  setSettingsDrawerOpen,
  getTutorialStatus,
  incrementTutorialStatus,
  decrementTutorialStatus,
  updateTutorial,
  completeTutorial,
}) => {
  const portalProps = usePortal();
  const { setIsLockedPortalComponentRendered } = portalProps;

  const props = useMemo(
    () =>
      tutorialId && {
        tutorialId,
        status: getTutorialStatus(tutorialId),
        requiredStartPath: TUTORIALS_CONFIG[tutorialId]?.requiredStartPath,
        incrementStatus: () => incrementTutorialStatus(tutorialId),
        decrementStatus: () => decrementTutorialStatus(tutorialId),
        updateStatus: (val) => updateTutorial(tutorialId, val),
        complete: () => {
          setIsLockedPortalComponentRendered(false);
          completeTutorial(tutorialId);
        },
      },
    [
      tutorialId,
      getTutorialStatus,
      incrementTutorialStatus,
      decrementTutorialStatus,
      updateTutorial,
      completeTutorial,
      setIsLockedPortalComponentRendered,
    ]
  );

  const tutorialConfig = TUTORIALS_CONFIG[tutorialId];

  if (!tutorialConfig || !tutorialConfig.getSteps) {
    return null;
  }

  const Component = tutorialConfig.component || BaseTutorial;

  return (
    <Component
      portalProps={portalProps}
      {...props}
      isSettingsDrawerOpen={isSettingsDrawerOpen}
      setSettingsDrawerOpen={setSettingsDrawerOpen}
      tutorialConfig={tutorialConfig}
      tutorialId={tutorialId}
    />
  );
};
