import { useCallback, useEffect, useMemo, useState } from "react";
import { TUTORIALS } from "../consts/tutorialConsts";
import { useTutorial } from "contexts/database/TutorialContext";
import { EMPTY_LIST } from "constants/defaults";
import { identity, isEqual, keyBy, values } from "lodash";
import { getSingleTutorialPerPage } from "../tutorialUtils";

export const useActiveTutorials = () => {
  const { tutorialStatusesReady, checkTutorialActive, ...rest } = useTutorial();
  const [activeTutorialIds, rawSetActiveTutorialIds] = useState([]);
  const activeTutorialIdsMap = useMemo(
    () => keyBy(activeTutorialIds, identity),
    [activeTutorialIds]
  );

  // only update active tutorial IDs if there's new ids
  const setActiveTutorialIds = useCallback((newIds) => {
    rawSetActiveTutorialIds((prev) => {
      const sortedNewIds = [...newIds].sort((a, b) => (a < b ? -1 : 1));
      return isEqual(sortedNewIds, prev) ? prev : sortedNewIds;
    });
  }, []);

  useEffect(() => {
    if (tutorialStatusesReady) {
      const activeTutorialIds = values(TUTORIALS).filter((tutorialId) =>
        checkTutorialActive(tutorialId)
      );

      setActiveTutorialIds(getSingleTutorialPerPage(activeTutorialIds));
    } else {
      setActiveTutorialIds(EMPTY_LIST);
    }
  }, [tutorialStatusesReady, checkTutorialActive, setActiveTutorialIds]);

  return {
    ...rest,
    activeTutorialIdsMap,
    activeTutorialIds,
  };
};
