import { useRef, useEffect, useCallback } from "react";
import { isNil, noop, throttle } from "lodash";

export const useDragDistance = ({
  ref,
  onDrag,
  onDragStart = noop,
  onDragEnd = noop,
}) => {
  const startX = useRef(0);
  const lastX = useRef(0);
  const startY = useRef(0);
  const lastY = useRef(0);

  useEffect(() => {
    const element = ref?.current;
    if (!element) return;

    // Throttled version of the onDrag callback
    const throttledOnDrag = throttle((change) => {
      onDrag(change);
    }, 0); // Adjust the delay as needed (in milliseconds)

    const handleMouseMove = (e) => {
      const deltaX = e.clientX - startX.current;
      const deltaY = e.clientY - startY.current;

      // Call the throttled onDrag callback with the drag distances
      throttledOnDrag({
        x: deltaX,
        y: deltaY,
        sincePrev: {
          x: e.clientX - lastX.current,
          y: e.clientY - lastY.current,
        },
        origin: { x: startX.current, y: startY.current },
        last: { x: lastX.current, y: lastY.current },
        destination: { x: e.clientX, y: e.clientY },
      });

      lastX.current = e.clientX;
      lastY.current = e.clientY;
    };

    const handleMouseUp = (e) => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      onDragEnd({ x: e.clientX, y: e.clientY });
      throttledOnDrag.cancel(); // Cancel any pending throttled calls
    };

    const handleMouseDown = (e) => {
      e.preventDefault();
      lastX.current = e.clientX;
      lastY.current = e.clientY;
      startX.current = e.clientX;
      startY.current = e.clientY;
      onDragStart({ x: e.clientX, y: e.clientY });

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    };

    // Add mousedown event listener to the element to start dragging
    element.addEventListener("mousedown", handleMouseDown);

    // Clean up event listeners on unmount
    return () => {
      element.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      throttledOnDrag.cancel(); // Clean up throttled calls on unmount
    };
  }, [ref, onDrag, onDragEnd, onDragStart]);
};

export const useDragValue = ({ ref, value, setValue, dragKey }) => {
  const startDrag = useRef();
  const curValue = useRef(value);
  curValue.current = value;

  const onDragStart = useCallback(() => {
    startDrag.current = curValue.current;
  }, []);

  const onDrag = useCallback(
    ({ [dragKey]: distance }) => {
      if (!isNil(startDrag.current)) {
        setValue(startDrag.current + distance);
      }
    },
    [dragKey, setValue]
  );

  const onDragEnd = useCallback(() => {
    startDrag.current = null;
  }, []);

  useDragDistance({ ref, onDrag, onDragStart, onDragEnd });
};
