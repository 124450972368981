import { TUTORIALS_CONFIG } from "./consts/tutorialsConfig";

// returns a filtered list where only the first tutorial on a given page will be active at a time
// if a tutorial doesn't have a page, it will always be included
export const getSingleTutorialPerPage = (tutorialIds) => {
  const selectedPages = new Set();
  const filteredList = [];

  tutorialIds.forEach((tutorialId) => {
    const config = TUTORIALS_CONFIG[tutorialId];
    const page = config?.page;

    if (!page || !selectedPages.has(page)) {
      filteredList.push(tutorialId);
      if (page) selectedPages.add(page);
    }
  });

  return filteredList;
};
