import { InitialLoadChangeLog } from "components/ChangeLog/ChangeLog";
import { Tutorials } from "components/Tutorial/Tutorials";
import React from "react";

export const PopupManager = () => {
  return (
    <>
      <Tutorials />
      <InitialLoadChangeLog />
    </>
  );
};
