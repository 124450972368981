import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { usePage } from "hooks/usePage";
import { TutorialTooltip } from "./TutorialTooltip";
import { TutorialModal } from "./TutorialModal";
import { useNavigator } from "components/Router/NavigatorContext";
import { isEmpty, isNil } from "lodash";
import { EMPTY_LIST } from "constants/defaults";
import { TUTORIAL_STEP_TYPES } from "./consts/tutorialConsts";

export const BaseTutorial = ({
  portalProps,
  status,
  incrementStatus,
  updateStatus,
  complete,
  requiredStartPath,
  tutorialConfig,
  tutorialId,
  isSettingsDrawerOpen,
  setSettingsDrawerOpen,
}) => {
  const { getSteps } = tutorialConfig;
  const { groupDoc } = useCurrentGroup();
  const [page, id] = usePage();
  const { navigate } = useNavigator();

  const steps = useMemo(
    () => getSteps({ groupDoc }) || EMPTY_LIST,
    [getSteps, groupDoc]
  );
  const stepProps = steps[status];

  const { type, shouldIncrementStep, shouldRender, isEnd, ...componentProps } =
    stepProps || {};

  // complete the tutorial if the step is missing for the current value
  useEffect(() => {
    if (!isNil(status) && isEmpty(stepProps)) {
      complete();
    }
  }, [complete, status, stepProps]);

  const stepWillIncrement = useMemo(
    () => shouldIncrementStep && shouldIncrementStep({ groupDoc, page, id }),
    [shouldIncrementStep, groupDoc, page, id]
  );

  // if the current step is fulfilled by a condition, wait for the condition to be filled before skipping the step
  useLayoutEffect(() => {
    if (stepWillIncrement) {
      incrementStatus();
    }
  }, [incrementStatus, stepWillIncrement]);

  // close the settings drawer if the modal should be opened
  useLayoutEffect(() => {
    if (
      !stepWillIncrement &&
      type === TUTORIAL_STEP_TYPES.MODAL &&
      isSettingsDrawerOpen
    ) {
      setSettingsDrawerOpen(false, true);
    }
  }, [
    tutorialId,
    isSettingsDrawerOpen,
    setSettingsDrawerOpen,
    type,
    stepWillIncrement,
  ]);

  // on mount, ensure user is on the required start page if at the start of the tutorial
  useEffect(() => {
    if (requiredStartPath && status === 0) {
      navigate({ to: requiredStartPath });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const shouldRenderProps = { groupDoc, page, id };
  const commonProps = {
    onButtonClick: isEnd ? complete : incrementStatus,
    onSkipToEnd:
      status === steps.length - 1
        ? undefined
        : () => updateStatus(steps.length - 1),
    shouldRender: shouldRender && shouldRender(shouldRenderProps),
    shouldRenderProps,
    isSettingsDrawerOpen,
  };

  if (stepWillIncrement) {
    return null;
  }

  return (
    <>
      {type === TUTORIAL_STEP_TYPES.TOOLTIP && stepProps && (
        <TutorialTooltip {...componentProps} {...commonProps} />
      )}
      {type === TUTORIAL_STEP_TYPES.MODAL && stepProps && (
        <TutorialModal
          key={`${tutorialId}.${status}`}
          {...componentProps}
          {...commonProps}
          portalProps={portalProps}
        />
      )}
    </>
  );
};
