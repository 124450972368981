import { values } from "lodash";

export const TUTORIALS = {
  INTRO: "intro",
};
export const TUTORIALS_LIST = values(TUTORIALS);

export const DEFAULT_TUTORIAL_STATUSES = {
  [TUTORIALS.INTRO]: 0,
};

export const TUTORIAL_PAGES = {
  GROUP: "GROUP",
  CHARACTER: "CHARACTER",
  SHIP: "SHIP",
  GM: "GM",
  COMPENDIUM: "COMPENDIUM",
};

export const TUTORIAL_STEP_TYPES = {
  MODAL: "MODAL",
  TOOLTIP: "TOOLTIP",
};
