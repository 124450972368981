import { useCallback, useRef } from "react";
import { DraggableRow } from "./Draggable";
import styles from "./textarea.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const Textarea = ({ className, containerRef, height, ...rest }) => {
  return (
    <textarea
      ref={containerRef}
      className={classNameBuilder("textarea", className)}
      style={{ height }}
      {...rest}
    />
  );
};

export const ResizableTextarea = ({
  containerClassName,
  value,
  height,
  setHeight,
  defaultHeight,
  minHeight = 70,
  maxHeight = 1000,
  shouldSeparateResizer,
  ...rest
}) => {
  const setValue = useCallback(
    (newHeight) => {
      return setHeight(Math.min(Math.max(newHeight, minHeight), maxHeight));
    },
    [setHeight, minHeight, maxHeight]
  );
  const ref = useRef();

  return (
    <div
      className={classNameBuilder("resizable-container", containerClassName, {
        "no-bottom": !shouldSeparateResizer,
      })}
    >
      <Textarea
        containerRef={ref}
        height={height || defaultHeight}
        {...rest}
        value={value}
      />
      <DraggableRow
        value={height || defaultHeight}
        className={classNameBuilder("resizer", {
          "no-bottom": !shouldSeparateResizer,
        })}
        setValue={setValue}
        onDoubleClick={() => {
          if (value) {
            setValue(ref.current?.scrollHeight || defaultHeight);
          }
        }}
        secondaryLight
        shouldRenderIndicator
      />
    </div>
  );
};
