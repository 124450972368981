import React, { useEffect, useMemo, useState } from "react";
import { Drawer } from "../Common/Drawer";
import { useTheme } from "../../contexts/ThemeContext";
import { Section } from "../Common/Section";
import styles from "./settingsDrawer.module.scss";
import { Button } from "../Common/Button";
import { useAuth } from "../../contexts/AuthContext";
import { Save, ScrollText, Undo2 } from "lucide-react";
import { SmallIcon } from "../Common/SmallIcon";
import { merge as fpMerge } from "lodash/fp";
import { isEqual } from "lodash";
import { ConfirmationModal } from "components/Common/Portals/ConfirmationModal/ConfirmationModal";
import {
  DEFAULT_TUTORIAL_STATUSES,
  TUTORIALS_LIST,
} from "components/Tutorial/consts/tutorialConsts";
import classNames from "classnames/bind";
import { usePortal } from "contexts/PortalContext";
import { ChangeLog } from "components/ChangeLog/ChangeLog";
import {
  SETTINGS_DRAWER_BOOLEAN_CATEGORIES,
  useSettingsDrawerBooleans,
} from "./useSettingsDrawerBooleans";
import { BooleanConfigSection } from "./SettingsDrawer/BooleanConfigSection";
import { ThemeSettings } from "./SettingsDrawer/ThemeSettings";
import { UserSettings } from "./SettingsDrawer/UserSettings";
import { SettingsDrawerKeybinds } from "./SettingsDrawer/SettingsDrawerKeybinds";
import { SettingsDrawerTutorialResetButton } from "./SettingsDrawer/SettingsDrawerTutorialResetButton";

const classNameBuilder = classNames.bind(styles);

export const SettingsDrawer = (props) => {
  const {
    portalProps,
    confirmationModalController,
    confirmPortalProps,
    shouldConfirmCloseRef,
  } = props;
  const { isPortalComponentRendered, setIsPortalComponentRendered } =
    portalProps;
  const {
    theme,
    setPrimaryTheme,
    setSecondaryTheme,
    setDiceTheme,
    setCustomDiceColor,
  } = useTheme();
  const { user, signOut, updateUser } = useAuth();
  const [newDiceColor, setNewDiceColor] = useState(theme.customDiceColor);
  const [tempUser, setTempUser] = useState(user);
  const changeLogPortalProps = usePortal();

  useEffect(() => {
    // merge temp into user so that changes are not lost
    setTempUser((prev) => fpMerge(prev)(user));
  }, [user]);

  // reset user on drawer open/close
  useEffect(() => {
    setTempUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortalComponentRendered]);

  const hasUserChanges = useMemo(
    () => !isEqual(user, tempUser),
    [tempUser, user]
  );

  useEffect(() => {
    shouldConfirmCloseRef.current = hasUserChanges;
  }, [hasUserChanges, shouldConfirmCloseRef]);

  const { booleanSubmitData, booleanConfigsByCategory } =
    useSettingsDrawerBooleans({
      tempUser,
    });

  const onSubmitUser = () => {
    updateUser(() => ({
      displayName: tempUser?.displayName || "Player",
      photoURL: tempUser?.photoURL || user.photoURL,
      tutorialStatuses:
        tempUser?.tutorialStatuses ||
        user.tutorialStatuses ||
        DEFAULT_TUTORIAL_STATUSES,
      ...booleanSubmitData,
    }));
  };

  const resetTempUser = () => {
    setTempUser(user);
  };

  const ResetStateButton = (
    <Button
      onClick={() => resetTempUser()}
      className={styles["user-state-button"]}
      disabled={!hasUserChanges}
    >
      <Undo2 />
      {"Undo changes"}
    </Button>
  );
  const SaveButton = (
    <Button
      onClick={() => onSubmitUser()}
      className={styles["user-state-button"]}
      disabled={!hasUserChanges}
      colorful={hasUserChanges}
    >
      <Save />
      {"Save Changes"}
    </Button>
  );

  return (
    <Drawer contentClassName={styles["container"]} {...props}>
      <ChangeLog portalProps={changeLogPortalProps} />
      <ConfirmationModal
        portalProps={confirmPortalProps}
        confirmationModalController={confirmationModalController}
        cancelClassName={styles["cancel"]}
        continueClassName={styles["continue"]}
      />
      <div className={styles["content"]}>
        <UserSettings
          tempUser={tempUser}
          setTempUser={setTempUser}
          onSubmitUser={onSubmitUser}
          user={user}
          hasUserChanges={hasUserChanges}
          resetTempUser={resetTempUser}
          booleanConfigsByCategory={booleanConfigsByCategory}
          signOut={signOut}
          setIsPortalComponentRendered={setIsPortalComponentRendered}
        />
        <Section
          label="General Settings"
          noBorder
          contentClassName={classNameBuilder("general", "section-content")}
        >
          <div className={styles["section-column"]}>
            <ThemeSettings
              theme={theme}
              setPrimaryTheme={setPrimaryTheme}
              setSecondaryTheme={setSecondaryTheme}
              setDiceTheme={setDiceTheme}
              newDiceColor={newDiceColor}
              setNewDiceColor={setNewDiceColor}
              setCustomDiceColor={setCustomDiceColor}
            />
            <BooleanConfigSection
              category={SETTINGS_DRAWER_BOOLEAN_CATEGORIES.GENERAL}
              tempUser={tempUser}
              setTempUser={setTempUser}
            />
            <BooleanConfigSection
              category={SETTINGS_DRAWER_BOOLEAN_CATEGORIES.COMPENDIUM}
              tempUser={tempUser}
              setTempUser={setTempUser}
            />
            <Section
              label={"Tutorials"}
              isSubSection
              className={styles["entry"]}
              contentClassName={styles["buttons-only"]}
            >
              {TUTORIALS_LIST.map((tutorialId) => (
                <SettingsDrawerTutorialResetButton
                  key={tutorialId}
                  tutorialId={tutorialId}
                  tempUser={tempUser}
                  setTempUser={setTempUser}
                />
              ))}
            </Section>
          </div>
          <div className={styles["section-column"]}>
            <BooleanConfigSection
              category={SETTINGS_DRAWER_BOOLEAN_CATEGORIES.DICE}
              tempUser={tempUser}
              setTempUser={setTempUser}
            />
            <BooleanConfigSection
              category={SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CUT}
              tempUser={tempUser}
              setTempUser={setTempUser}
            />
            <BooleanConfigSection
              category={SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT}
              tempUser={tempUser}
              setTempUser={setTempUser}
            />
          </div>
        </Section>
        <Section
          label="Miscellaneous Info"
          noBorder
          contentClassName={classNameBuilder(
            "general",
            "section-content",
            "wide"
          )}
        >
          <SettingsDrawerKeybinds />
          <div className={classNameBuilder("setting-container", "split")}>
            <div />
            <Button
              onClick={() =>
                changeLogPortalProps.setIsPortalComponentRendered(true)
              }
              className={styles["button"]}
            >
              <SmallIcon>
                <ScrollText className={styles["footer-icon"]} />
              </SmallIcon>
              {"View Changelog"}
            </Button>
          </div>
        </Section>
      </div>

      <div className={styles["footer"]}>
        {ResetStateButton}
        {SaveButton}
      </div>
    </Drawer>
  );
};
