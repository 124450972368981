import { Button } from "components/Common/Button";
import { TUTORIALS_CONFIG } from "components/Tutorial/consts/tutorialsConfig";
import { fpSet } from "lib/fpLodash";
import { get, isNil } from "lodash";
import React from "react";

export const SettingsDrawerTutorialResetButton = ({
  tempUser,
  setTempUser,
  tutorialId,
}) => {
  const status = get(tempUser, ["tutorialStatuses", tutorialId]);
  const isReset = status === 0 || isNil(status);

  let text = `Reset ${TUTORIALS_CONFIG[tutorialId]?.label} Tutorial`;

  if (isReset) {
    text = `${TUTORIALS_CONFIG[tutorialId]?.label} Tutorial Ready`;
  }

  return (
    <Button
      onClick={() =>
        setTempUser((prev) => fpSet(["tutorialStatuses", tutorialId], 0, prev))
      }
      colorful={isReset}
      disabled={isReset}
      style={{
        textAlign: "center",
        height: "unset",
        minHeight: "40px",
      }}
    >
      {text}
    </Button>
  );
};
