import { useDragValue } from "hooks/listeners/useDragDistance";
import styles from "./draggable.module.scss";
import classnames from "classnames/bind";
import { useRef } from "react";
import { Tooltip } from "./Tooltip/Tooltip";

const classNameBuilder = classnames.bind(styles);

export const Draggable = ({
  className,
  secondaryLight,
  contrast,
  value,
  shouldRenderIndicator,
  setValue,
  dragKey,
  children,
  indicatorClassName,
  ...rest
}) => {
  const ref = useRef();

  useDragValue({ value, setValue, ref, dragKey });

  return (
    <div
      ref={ref}
      className={classNameBuilder("default", className, {
        "secondary-light": secondaryLight,
        contrast,
      })}
      {...rest}
    >
      <Tooltip delay={800} position={"bottom"}>
        <div>Click and drag to re-size</div>
      </Tooltip>
      {shouldRenderIndicator && (
        <div className={styles["indicator"]}>
          <div className={styles["line"]} />
        </div>
      )}
      {children}
    </div>
  );
};

export const DraggableRow = ({ className, dragKey = "y", ...rest }) => (
  <Draggable
    className={classNameBuilder("row", className)}
    dragKey={dragKey}
    {...rest}
  />
);
export const DraggableColumn = ({
  className,
  dragKey = "x",
  indicatorClassName,
  ...rest
}) => (
  <Draggable
    className={classNameBuilder("column", className)}
    indicatorClassName={classNameBuilder(
      "column-indicator",
      indicatorClassName
    )}
    dragKey={dragKey}
    {...rest}
  />
);
