import { useEffect, useRef } from "react";
import { PosterModal } from "components/Common/Portals/PosterModal/PosterModal";

export const TutorialModal = ({
  portalProps,
  isSettingsDrawerOpen,
  buttonLabel = "NEXT",
  onButtonClick,
  ...rest
}) => {
  const { isPortalComponentRendered, setIsLockedPortalComponentRendered } =
    portalProps;
  const portalOpenedRef = useRef(false);

  useEffect(() => {
    // if the settings drawer is open or the modal has already been opened, do nothing
    if (isSettingsDrawerOpen || portalOpenedRef.current) {
      return;
    }

    portalOpenedRef.current = true;
    setIsLockedPortalComponentRendered(true);
  }, [
    isPortalComponentRendered,
    isSettingsDrawerOpen,
    setIsLockedPortalComponentRendered,
  ]);

  return (
    <PosterModal
      portalProps={portalProps}
      buttonLabel={buttonLabel}
      onButtonClick={(e) => {
        onButtonClick(e);
        setIsLockedPortalComponentRendered(false);
      }}
      {...rest}
    />
  );
};
