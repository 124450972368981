import { GROUPS_PATH } from "components/Groups/constants/groupsConsts";
import {
  getIntroTutorialSteps,
  IntroTutorial,
} from "../steps/introTutorialSteps";
import { TUTORIALS } from "./tutorialConsts";

export const TUTORIALS_CONFIG = {
  [TUTORIALS.INTRO]: {
    label: "Intro",
    component: IntroTutorial,
    getSteps: getIntroTutorialSteps,
    requiredStartPath: `/${GROUPS_PATH}`,
  },
};
