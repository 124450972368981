import { Loader } from "./Loader";
import styles from "./section.module.scss";
import classnames from "classnames/bind";
import { useCallback, useState } from "react";
import { noop } from "lodash";
import { Chevron } from "./Shape/Chevron";
import { ExpansionLogo } from "./ExpansionLogo";
import { useAuth } from "contexts/AuthContext";
import {
  getSettingsDrawerBooleanValue,
  SETTINGS_DRAWER_BOOLEANS,
} from "components/Header/useSettingsDrawerBooleans";
import { Button } from "./Button";
import { Lock, Unlock } from "lucide-react";
import { Tooltip } from "./Tooltip/Tooltip";
import { DraggableRow } from "./Draggable";

const classNameBuilder = classnames.bind(styles);

export const Section = ({
  label,
  sublabel,
  headerSuffix,
  children,
  noBorder,
  fill,
  updating,
  className,
  headerClassName,
  contentClassName,
  headerSuffixClassName,
  labelClassName,
  expandableIconClassName,
  noPadding,
  disabled,
  isSubSection,
  contrast,
  gray,
  expandable,
  initialExpanded,
  fullyCollapsible: propsFullyCollapsible,
  onExpandChange = noop,
  unexpandedClassName = "unexpanded",
  expandedClassName = "expanded",
  expansion,
  expansionTooltipProps,
  underSection,
  height,
  hiddenContent,
  containerRef,

  lockable,
  locked,
  setLocked,
}) => {
  const { user } = useAuth();
  const shouldFullyCollapseSections = getSettingsDrawerBooleanValue(
    SETTINGS_DRAWER_BOOLEANS.FULLY_COLLAPSED_SECTIONS,
    user || {}
  );
  const fullyCollapsible =
    expandable && (shouldFullyCollapseSections || propsFullyCollapsible);
  const [expanded, setExpanded] = useState(initialExpanded);

  let expandClassName = "";

  if (expandable) {
    expandClassName = expanded ? expandedClassName : unexpandedClassName;
  }

  return (
    <div
      className={classNameBuilder("container", className)}
      ref={containerRef}
    >
      <div
        onClick={() =>
          expandable &&
          setExpanded((prev) => {
            onExpandChange(!prev);
            return !prev;
          })
        }
        className={classNameBuilder("header", headerClassName, {
          secondary: isSubSection,
          contrast,
          gray,
          disabled,
          expandable,
        })}
      >
        {expandable && (
          <Chevron
            opened={expanded}
            className={classNameBuilder("chevron", expandableIconClassName)}
          />
        )}
        <span className={classNameBuilder("label", labelClassName)}>
          {label}
          {sublabel && (
            <span className={classNameBuilder("sublabel")}>{sublabel}</span>
          )}
          {expansion && (
            <div className={styles["expansion-logo"]}>
              <ExpansionLogo
                type={expansion}
                tooltipProps={expansionTooltipProps}
              />
            </div>
          )}
        </span>
        <div className={styles["suffix"]}>
          {Boolean(updating) && <Loader className={styles["loader"]} />}
          {Boolean(lockable) && (
            <Button
              icon
              square
              hideBackground
              fitContent
              className={classNameBuilder("lock-button", { locked })}
              onClick={(e) => {
                e.stopPropagation();
                setLocked((prev) => !prev);
              }}
            >
              {locked ? <Lock /> : <Unlock />}
              <Tooltip position={"bottom-end"} offsetBody={20}>
                Click to {locked ? "allow" : "prevent"} changes to this section
              </Tooltip>
            </Button>
          )}
          {headerSuffix && (
            <div
              className={classNameBuilder(
                "header-suffix",
                headerSuffixClassName
              )}
              onClick={(e) => e.stopPropagation()}
            >
              {headerSuffix}
            </div>
          )}
        </div>
      </div>
      <div
        className={classNameBuilder("buffer", {
          borderless: noBorder,
          secondary: isSubSection,
          gray,
          disabled,
          contrast,
          "no-padding": noPadding,
          collapsed: fullyCollapsible && !expanded,
        })}
      />
      {fullyCollapsible && !expanded && !hiddenContent ? null : (
        <div
          style={{
            height,
          }}
          className={classNameBuilder(
            "content",
            contentClassName,
            expandClassName,
            {
              hidden: fullyCollapsible && !expanded && hiddenContent,
              borderless: noBorder,
              secondary: isSubSection,
              contrast,
              gray,
              disabled,
              "no-padding": noPadding,
              fill,
            }
          )}
        >
          {children}
        </div>
      )}
      {underSection}
    </div>
  );
};

export const ResizableSection = ({
  contentClassName,
  isSubSection,
  height,
  setHeight,
  defaultHeight,
  minHeight,
  maxHeight,
  initialExpanded,
  expandable,
  ...rest
}) => {
  const setHeightValue = useCallback(
    (newHeight) => {
      return setHeight(Math.min(Math.max(newHeight, minHeight), maxHeight));
    },
    [setHeight, minHeight, maxHeight]
  );

  return (
    <Section
      underSection={
        (initialExpanded || !expandable) && (
          <DraggableRow
            secondaryLight={isSubSection}
            className={styles["resizer"]}
            shouldRenderIndicator
            value={height || defaultHeight}
            setValue={setHeightValue}
          />
        )
      }
      expandable={expandable}
      initialExpanded={initialExpanded}
      isSubSection={isSubSection}
      contentClassName={classNameBuilder("resizable-content", contentClassName)}
      height={height || defaultHeight}
      {...rest}
    />
  );
};
