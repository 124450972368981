import { useAuth } from "contexts/AuthContext";
import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { get, noop } from "lodash";
import { DEFAULT_TUTORIAL_STATUSES } from "components/Tutorial/consts/tutorialConsts";
import { EMPTY_LIST, EMPTY_OBJECT } from "constants/defaults";
import { TUTORIALS_CONFIG } from "components/Tutorial/consts/tutorialsConfig";
import { useSectionState } from "components/Characters/components/hooks/useSectionState";

const TutorialContext = createContext(null);

export const TutorialProvider = ({ children }) => {
  const { user, updateUser } = useAuth();

  const updateTutorialStatuses = useCallback(
    (id, updater) => {
      return updateUser(updater);
    },
    [updateUser]
  );

  const [tutorialStatuses, setTutorialStatuses] = useSectionState({
    originalState: user?.tutorialStatuses || EMPTY_OBJECT,
    id: user?.uid,
    key: "tutorialStatuses",
    updateItem: user?.uid ? updateTutorialStatuses : noop,
    delay: 100,
    shouldDisableDirty: true,
  });

  const userId = user?.uid;
  const userTutorialStatuses = user?.tutorialStatuses;

  // instantiate tutorialStatuses or populate user's initial statuses
  useEffect(() => {
    if (!userId) {
      return;
    }
    if (!userTutorialStatuses) {
      setTutorialStatuses(DEFAULT_TUTORIAL_STATUSES);
    }
  }, [setTutorialStatuses, userId, userTutorialStatuses]);

  const updateTutorial = useCallback(
    (tutorialId, status) => {
      setTutorialStatuses((prev) => ({
        ...DEFAULT_TUTORIAL_STATUSES,
        ...prev,
        [tutorialId]: status,
      }));
    },
    [setTutorialStatuses]
  );

  const tutorialStatusesReady = useMemo(
    () => Boolean(user) && Boolean(tutorialStatuses),
    [tutorialStatuses, user]
  );
  const getTutorialStatus = useCallback(
    (tutorialId) => get(tutorialStatuses, [tutorialId], 0),
    [tutorialStatuses]
  );

  const checkTutorialFinished = useCallback(
    (tutorialId) => getTutorialStatus(tutorialId) < 0,
    [getTutorialStatus]
  );

  const checkTutorialPrereqsSatisfied = useCallback(
    (tutorialId) => {
      return get(
        TUTORIALS_CONFIG,
        [tutorialId, "prereq", "tutorials"],
        EMPTY_LIST
      ).every((prereqTutorialid) => checkTutorialFinished(prereqTutorialid));
    },
    [checkTutorialFinished]
  );

  const checkTutorialActive = useCallback(
    (tutorialId) =>
      !checkTutorialFinished(tutorialId) &&
      checkTutorialPrereqsSatisfied(tutorialId),
    [checkTutorialFinished, checkTutorialPrereqsSatisfied]
  );

  const incrementTutorialStatus = useCallback(
    (tutorialId) => {
      const currentStatus = getTutorialStatus(tutorialId);
      updateTutorial(tutorialId, currentStatus + 1);
    },
    [getTutorialStatus, updateTutorial]
  );

  const decrementTutorialStatus = useCallback(
    (tutorialId) => {
      const currentStatus = getTutorialStatus(tutorialId);
      const newStatus = Math.max(currentStatus - 1, 0);
      if (newStatus === currentStatus) {
        return false;
      }
      updateTutorial(tutorialId, currentStatus - 1);
      return true;
    },
    [getTutorialStatus, updateTutorial]
  );

  const completeTutorial = useCallback(
    (tutorialId) => {
      updateTutorial(tutorialId, -1);
    },
    [updateTutorial]
  );

  const value = useMemo(
    () => ({
      tutorialStatusesReady,
      getTutorialStatus,
      decrementTutorialStatus,
      incrementTutorialStatus,
      updateTutorial,
      completeTutorial,
      checkTutorialActive,
    }),
    [
      tutorialStatusesReady,
      getTutorialStatus,
      decrementTutorialStatus,
      incrementTutorialStatus,
      updateTutorial,
      completeTutorial,
      checkTutorialActive,
    ]
  );

  return (
    <TutorialContext.Provider value={value}>
      {children}
    </TutorialContext.Provider>
  );
};

export const useTutorial = (id) => {
  const context = useContext(TutorialContext);
  if (!context) {
    throw new Error(`useTutorial must be used within a TutorialProvider`);
  }
  return context;
};
