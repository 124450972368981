import React from "react";
import { DatabaseContextProvider } from "./contexts/database/DatabaseContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "./contexts/ThemeContext";
import { DiceboxProvider } from "./contexts/DiceboxContext";
import { PortalProvider } from "./contexts/PortalContext";
import { AppHeaderProvider } from "./contexts/AppHeaderContext";
import { AuthContextProvider } from "./contexts/AuthContext";
import { TooltipStyleProvider } from "contexts/TooltipStyleContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DocumentManagerProvider } from "contexts/database/DocumentManagerContext";
import { RouterProvider } from "@tanstack/react-router";
import { router } from "components/Router/Router";
import { ChatHelperProvider } from "contexts/ChatHelperContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const Providers = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      <DocumentManagerProvider>
        <DatabaseContextProvider>
          <AuthContextProvider>
            <ThemeProvider>
              <TooltipStyleProvider>
                <PortalProvider>
                  <DiceboxProvider>
                    <AppHeaderProvider>
                      <ChatHelperProvider>
                        <RouterProvider router={router} />
                      </ChatHelperProvider>
                    </AppHeaderProvider>
                  </DiceboxProvider>
                </PortalProvider>
              </TooltipStyleProvider>
            </ThemeProvider>
          </AuthContextProvider>
        </DatabaseContextProvider>
      </DocumentManagerProvider>
    </QueryClientProvider>
  );
};
