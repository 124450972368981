import { useEffect, useRef, useState } from "react";
import { isEqual, set } from "lodash";
import { useNavigator } from "components/Router/NavigatorContext";
import { useStateRef } from "hooks/useStateRef";

const MAX_RETRIES = 5;

export const useSectionState = ({
  originalState,
  id,
  key,
  isTest = false,
  updateItem,
  delay = 2000,
  shouldDisableDirty,
}) => {
  const [updating, setUpdating] = useState(0);
  const [targetState, setTargetState] = useState(originalState);
  const debounceTimer = useRef(null);
  const insideTimerRef = useRef(false);
  const insideTimerCallback = useRef(null);
  const { decrementDirty, incrementDirty } = useNavigator();

  const [hasIncremented, setHasIncremented, hasIncrementedRef] =
    useStateRef(false);

  // increment dirty
  useEffect(() => {
    const equalStates = isEqual(originalState, targetState);

    if (hasIncrementedRef.current && equalStates) {
      setHasIncremented(false);
      !shouldDisableDirty && decrementDirty({ fieldId: key });
    } else if (!hasIncrementedRef.current && !equalStates) {
      setHasIncremented(true);
      !shouldDisableDirty && incrementDirty({ fieldId: key });
    }
  }, [
    decrementDirty,
    hasIncrementedRef,
    id,
    incrementDirty,
    key,
    originalState,
    setHasIncremented,
    shouldDisableDirty,
    targetState,
  ]);

  useEffect(() => {
    return () => {
      insideTimerCallback.current = null;
      clearTimeout(debounceTimer.current);
    };
  }, []);

  // debounce update based on targetState
  useEffect(() => {
    if (originalState === targetState || isEqual(originalState, targetState)) {
      return clearTimeout(debounceTimer.current);
    }

    const clearAndSetTimer = () => {
      insideTimerCallback.current = null;
      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(async () => {
        setUpdating((prev) => prev + 1);
        insideTimerRef.current = true;

        if (isTest) {
          console.log(set({}, key, targetState));
          return;
        }

        await new Promise(async (res, rej) => {
          let retries = 0;
          const forceUpdate = async () => {
            try {
              await updateItem(id, () => {
                return set({}, key, targetState);
              });

              res();
            } catch (err) {
              console.error(err);
              retries += 1;

              if (retries > MAX_RETRIES) {
                res();
                alert(
                  `Failed to update ${id}, please refresh the page. Your data will likely be lost so try to back up the value if possible. Sorry for the inconvenience!`
                );

                return;
              }

              setTimeout(forceUpdate, 500);
            }
          };

          forceUpdate();
        });

        setUpdating((prev) => prev - 1);

        // clear timers, mark the timer has resolved,
        // and trigger the next timer if there is one
        debounceTimer.current = null;
        insideTimerRef.current = false;
        insideTimerCallback.current && insideTimerCallback.current();
      }, delay);
    };

    // if another timer is currently resolving its update,
    // batch this update until that timer finishes
    if (insideTimerRef.current) {
      insideTimerCallback.current = clearAndSetTimer;
      // otherwise, start the timer
    } else {
      clearAndSetTimer();
    }
    // eslint-disable-next-line
  }, [hasIncremented, targetState, isTest, delay]);

  // reset targetState when originalState changes as long as a change isn't buffering
  //    (triggered by the document being updated from the timer or from another person updating
  //    the same document)
  useEffect(() => {
    if (!debounceTimer.current && !insideTimerRef.current) {
      setTargetState(originalState);
    }
  }, [originalState, id]);

  return [targetState, setTargetState, { updating, setUpdating }];
};
