import { useEffect, useRef } from "react";
import { getDataFromDocument } from "../contexts/database/useMainAccessors";
import { noop } from "lodash";
import { useDocumentManager } from "contexts/database/DocumentManagerContext";
import { supportEmail } from "constants/contactInfo";

export const useDocumentSync = ({
  subscribe,
  id,
  queryKey,
  disabled,
  // this means refresh the document group, not the Group
  refetchGroup = noop,
  onUpdateSuccess = noop,
  warningName = "sheet",
}) => {
  const prevDoc = useRef();
  const { set: setInCache } = useDocumentManager();
  const warningTimer = useRef(true);

  useEffect(() => {
    return () => {
      clearTimeout(warningTimer.current);
    };
  }, []);

  useEffect(() => {
    if (disabled || !id) {
      return;
    }
    prevDoc.current = null;
    warningTimer.current = setTimeout(() => {
      alert(
        `We were not able to sync this ${warningName} (${id}) with its latest value in the expected amount of time. We recommend refreshing the page.\n\nIf this notice happens frequently, please send an email to ${supportEmail} and we'll investigate the issue. Please include a screenshot of this alert and the current URL.`
      );
    }, 10000);

    let unsubscribe = noop;
    try {
      unsubscribe = subscribe(id, (doc) => {
        clearTimeout(warningTimer.current);
        const newDocData = getDataFromDocument(doc);

        setInCache([queryKey, id], (old) => {
          prevDoc.current = old;

          if (old?.name !== newDocData?.name) {
            refetchGroup();
          }

          onUpdateSuccess(newDocData);

          return newDocData;
        });

        refetchGroup();
      });
    } catch (err) {
      alert(
        `We were not able to sync this ${warningName} (${id}) with its latest value. We recommend you refresh the page.\n\nError: ${err?.message || "Unknown Error"}\n\nIf this notice happens frequently, please send an email to ${supportEmail} and we'll investigate the issue. Please include a screenshot of this alert and the current URL.`
      );
      clearTimeout(warningTimer.current);
    }

    return () => {
      unsubscribe();
      clearTimeout(warningTimer.current);
    };
  }, [
    disabled,
    id,
    queryKey,
    subscribe,
    refetchGroup,
    onUpdateSuccess,
    setInCache,
    warningName,
  ]);
};
