import { useQuery } from "@tanstack/react-query";

export const usePreloadImage = (src) => {
  return useQuery({
    queryKey: ["PRELOAD_IMAGE", src],
    staleTime: Infinity,
    gcTime: Infinity,
    queryFn: () => {
      if (!src) {
        return "";
      }

      const img = new Image();
      img.src = src;

      return src;
    },
  });
};
